import { useEffect, useState } from 'react';
import { getAllUsers } from '../../api/user';
import { getAllReceipts } from '../../api/receipt';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import Chart from 'react-apexcharts';
import ProtectedRoute from '../../router/ProtectedRoute';
import useAuth from '../../auth/useAuth';
import MUIDataTable from 'mui-datatables';

export default function MarketingPage() {
  const { session } = useAuth();
  // const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [tarifaCounts, setTarifaCounts] = useState({}); // Initialize an empty object to store tarifa counts
  const [receiptsPerDay, setReceiptsPerDay] = useState({}); // Initialize an empty object to store receipts per day
  const [chartData, setChartData] = useState(null);
  const [usersPerDay, setUsersPerDay] = useState({});
  const [userChartData, setUserChartData] = useState(null);

  useEffect(() => {
    checkUsers();
    checkReceipts();
  }, []);

    useEffect(() => {
    document.title = 'Dashboard';
  }, []);

  const checkUsers = async () => {
    try {
      const response = (await getAllUsers(session.accessToken)) || false;

      // setLoading(false);
      setUsers(response.data);

      // Calculate receipts uploaded per day
      const dayCounts = {};

      // Find the oldest and newest date
      let oldestDate = new Date();
      let newestDate = new Date(0);

      response.data.forEach((user) => {
        const updatedAt = new Date(user.createdAt);
        const date = updatedAt.toDateString();
        dayCounts[date] = (dayCounts[date] || 0) + 1;

        // Update the oldest and newest date
        if (updatedAt < oldestDate) {
          oldestDate = updatedAt;
        }
        if (updatedAt > newestDate) {
          newestDate = updatedAt;
        }
      });

      // Create an array of dates from oldest to newest
      const allDates = [];
      let currentDate = new Date(oldestDate);
      while (currentDate <= newestDate) {
        allDates.push(currentDate.toDateString());
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Fill in missing dates with count 0
      allDates.forEach((date) => {
        if (!dayCounts[date]) {
          dayCounts[date] = 0;
        }
      });

      // Sort the dates in chronological order
      const sortedDates = allDates.sort((a, b) => new Date(a) - new Date(b));

      // Use ApexCharts data format
      const chartData = {
        type: 'line',
        height: 500,
        options: {
          chart: {
            sparkline: {
              enabled: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#fff'],
          fill: {
            type: 'solid',
            opacity: 1,
          },
          stroke: {
            curve: 'smooth',
            width: 3,
          },
          yaxis: {
            min: 0,
            max: Math.max(...Object.values(dayCounts)),
            title: {
              text: 'Y Label Here',
            },
          },
          xaxis: {
            title: {
              text: 'Fechas', // Set the X label
            },
            categories: sortedDates, // Include your date values here
          },
          tooltip: {
            theme: 'dark',
            fixed: {
              enabled: false,
            },
            x: {
              show: true, // Show X value in tooltip
              format: 'dd MMM yyyy', // Format for date display in tooltip
            },
            y: {
              title: 'Total Orders',
            },
            marker: {
              show: false,
            },
          },
        },
        series: [
          {
            name: 'conteo',
            data: sortedDates.map((date) => dayCounts[date]),
          },
        ],
      };

      setUsersPerDay(dayCounts);
      setUserChartData(chartData);
    } catch (e) {
      console.log('catch error on check services from services', e);
    }
  };

  const checkReceipts = async () => {
    try {
      const response = (await getAllReceipts(session.accessToken)) || false;
      setReceipts(response.data);

      // Calculate tarifa counts
      const counts = {}; // Temporary object to hold counts
      response.data.forEach((receipt) => {
        const tarifa = receipt.tarifa;
        counts[tarifa] = (counts[tarifa] || 0) + 1;
      });

      // Sort tarifas alphabetically
      const sortedTarifas = Object.keys(counts).sort();

      const sortedTarifaCounts = {};
      sortedTarifas.forEach((tarifa) => {
        sortedTarifaCounts[tarifa] = counts[tarifa];
      });

      setTarifaCounts(sortedTarifaCounts); // Update tarifa counts

      // Calculate receipts uploaded per day
      const dayCounts = {};

      // Find the oldest and newest date
      let oldestDate = new Date();
      let newestDate = new Date(0);

      response.data.forEach((receipt) => {
        const updatedAt = new Date(receipt.updatedAt);
        const date = updatedAt.toDateString();
        dayCounts[date] = (dayCounts[date] || 0) + 1;

        // Update the oldest and newest date
        if (updatedAt < oldestDate) {
          oldestDate = updatedAt;
        }
        if (updatedAt > newestDate) {
          newestDate = updatedAt;
        }
      });

      // Create an array of dates from oldest to newest
      const allDates = [];
      let currentDate = new Date(oldestDate);
      while (currentDate <= newestDate) {
        allDates.push(currentDate.toDateString());
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Fill in missing dates with count 0
      allDates.forEach((date) => {
        if (!dayCounts[date]) {
          dayCounts[date] = 0;
        }
      });

      // Sort the dates in chronological order
      const sortedDates = allDates.sort((a, b) => new Date(a) - new Date(b));

      // Use ApexCharts data format
      const chartData = {
        type: 'line',
        height: 500,
        options: {
          chart: {
            sparkline: {
              enabled: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#fff'],
          fill: {
            type: 'solid',
            opacity: 1,
          },
          stroke: {
            curve: 'smooth',
            width: 3,
          },
          yaxis: {
            min: 0,
            max: Math.max(...Object.values(dayCounts)),
            title: {
              text: 'Y Label Here',
            },
          },
          xaxis: {
            title: {
              text: 'Fechas', // Set the X label
            },
            categories: sortedDates, // Include your date values here
          },
          tooltip: {
            theme: 'dark',
            fixed: {
              enabled: false,
            },
            x: {
              show: true, // Show X value in tooltip
              format: 'dd MMM yyyy', // Format for date display in tooltip
            },
            y: {
              title: 'Total Orders',
            },
            marker: {
              show: false,
            },
          },
        },
        series: [
          {
            name: 'conteo',
            data: sortedDates.map((date) => dayCounts[date]),
          },
        ],
      };

      setReceiptsPerDay(dayCounts);
      setChartData(chartData);
    } catch (e) {
      console.log('catch error on check services from services', e);
    }
  };

  // console.log('users', users);
  // console.log('receipts', receipts);
  // console.log('receiptsPerDay', receiptsPerDay);

  // Sort dates by most recent first
  const sortedDates = Object.keys(receiptsPerDay).sort(
    (a, b) => new Date(b) - new Date(a)
  );
  const userSortedDates = Object.keys(usersPerDay).sort(
    (a, b) => new Date(b) - new Date(a)
  );

  let x = 2;

  const columns = [
    {
      name: 'tarifa',
      label: 'Tarifa',
    },
    {
      name: 'conteo',
      label: 'Conteo',
    },
  ];

  const options = {
    filterType: 'checkbox',
    rowsPerPage: 15,
    // onRowClick: handleRowClick,
    selectableRows: 'none', // If you don't want to allow selecting rows
    responsive: 'vertical',
    download: false, // Disable download button
    print: false, // Disable print button
    search: false, // Disable search bar
    viewColumns: false, // Disable column view toggle
    selectableRowsHeader: false, // Disable header checkbox
    selectableRowsHideCheckboxes: true, // Hide checkboxes
    pagination: false, // Disable pagination
    customFooter: () => (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell>{receipts.length}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    ),
  };

  const usuariosColumns = [
    {
      name: 'totalUsers',
      label: 'Total de Usuarios',
    },
  ];

  const usuariosData = [
    {
      totalUsers: users.length - 28,
    },
  ];

  const usuariosOptions = {
    filterType: 'checkbox',
    rowsPerPage: 15,
    selectableRows: 'none',
    responsive: 'vertical',
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    pagination: false,
    customFooter: () => null,
  };

  const userDatesColumns = [
    {
      name: 'date',
      label: 'Fecha',
    },
    {
      name: 'count',
      label: 'Conteo',
    },
    {
      name: 'totalUsers',
      label: 'Total de Usuarios',
    },
  ];

  const reversedUserDatesData = userSortedDates.map((date, index) => {
    const totalCount = userSortedDates
      .slice(index)
      .reduce((acc, currDate) => acc + usersPerDay[currDate], 0);

    return {
      date,
      count: usersPerDay[date],
      totalUsers: totalCount,
    };
  });

  const userDatesOptions = {
    filterType: 'checkbox',
    rowsPerPage: 10,
    selectableRows: 'none',
    responsive: 'vertical',
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    pagination: true,
  };

  const sortedDatesColumns = [
    {
      name: 'date',
      label: 'Fecha',
    },
    {
      name: 'count',
      label: 'Conteo',
    },
    {
      name: 'totalReceipts',
      label: 'Total de Recibos',
    },
  ];

  const sortedDatesOptions = {
    filterType: 'checkbox',
    rowsPerPage: 10,
    selectableRows: 'none',
    responsive: 'vertical',
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    pagination: true,
  };

  const reversedSortedDatesData = sortedDates.map((date, index) => {
    const totalCount = sortedDates
      .slice(index)
      .reduce((acc, currDate) => acc + receiptsPerDay[currDate], 0);

    return {
      date,
      count: receiptsPerDay[date],
      totalReceipts: totalCount,
    };
  });

  return (
    <ProtectedRoute>
      <div
        style={{
          padding: 20,
        }}
      >
        <div
          style={{
            backgroundColor: '#eef2f6',
            width: '100%',
            height: '100%',
            minHeight: 'calc(100vh - 40px)',
            borderRadius: 10,
            padding: 20,
          }}
        >
          {x == 1 ? null : (
            <>
              <MUIDataTable
                title={'Cuentas creadas'}
                columns={usuariosColumns}
                data={usuariosData}
                options={usuariosOptions}
                className='mui-datatables-container'
              />

              <MUIDataTable
                title={'Tarifas'}
                columns={columns}
                data={Object.keys(tarifaCounts).map((tarifa) => ({
                  tarifa,
                  conteo: tarifaCounts[tarifa],
                }))}
                options={options}
                className='mui-datatables-container'
              />
              <MUIDataTable
                title={'Fechas de Usuarios'}
                columns={userDatesColumns}
                data={reversedUserDatesData}
                options={userDatesOptions}
                className='mui-datatables-container'
              />
              <MUIDataTable
                title={'Fechas de Recibos'}
                columns={sortedDatesColumns}
                data={reversedSortedDatesData}
                options={sortedDatesOptions}
                className='mui-datatables-container'
              />
              {/* <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Usuarios</TableCell>
                      <TableCell>{users.length - 28}</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer> */}
              <div style={{ backgroundColor: '#272262', padding: 10 }}>
                <h3 style={{ color: '#fff' }}>
                  Histórico de creación de usuarios
                </h3>
                {/* <Chart {...chartData} /> */}

                <div>{userChartData && <Chart {...userChartData} />}</div>
              </div>

              <div style={{ backgroundColor: '#884DB8', padding: 10 }}>
                <h3 style={{ color: '#fff' }}>
                  Histórico de subida de recibos
                </h3>
                {/* <Chart {...chartData} /> */}

                <div>{chartData && <Chart {...chartData} />}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </ProtectedRoute>
  );
}

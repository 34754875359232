//All user API's are found in this document

//Resets a password for a certain user.
async function resetPassword(userData) {
  try {
    let requestOptions = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
      body: userData,
      redirect: 'follow',
    };

    const response = await fetch(
      `${process.env.REACT_APP_RESET_PASSWORD}`,
      requestOptions
    );

    return await response.json();
  } catch (error) {
    throw error;
  }
}

//Returns all users. (Used at Profile)
async function getAllUsers(accessToken) {
  try {
    let requestOptions = {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'User-Agent': 'app',
      },
      redirect: 'follow',
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users`,
      requestOptions
    );

    return response.json();
  } catch (error) {
    console.log(error);
    throw error;
  }
}
async function getUserByUserId(accessToken, userId) {
  try {
    let requestOptions = {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'User-Agent': 'app',
      },
      redirect: 'follow',
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/${userId}`,
      requestOptions
    );

    return response.json();
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export { resetPassword, getAllUsers, getUserByUserId };

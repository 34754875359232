import { lazy, useEffect } from 'react';
import { Link } from 'react-router-dom';

import image from './404.png';
import { Button } from '../../common/Button';
import {
    ButtonWrapper,
} from '../../components/HeroSection/styles';
import PublicRoute from '../../router/PublicRoute';
const Container = lazy(() => import('../../common/Container'));
const ScrollToTop = lazy(() => import('../../common/ScrollToTop'));


const NotFound = () => {
    useEffect(() => {
        document.title = "Not Found";
    }, []);

    return (
        <PublicRoute>
        <Container>
            <ScrollToTop />
            <div style={{ height: '90vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={image} alt="Description" />
                <ButtonWrapper>
                    <Link to="/">
                        <Button fixedWidth={true}>
                            Volver a inicio
                        </Button>
                    </Link>
                </ButtonWrapper>
            </div>
        </Container>
        </PublicRoute>
    );
};

export default NotFound;

import { useEffect, useState } from 'react';
import { getClientsByStoreId } from '../../api/clients';
import ProtectedRoute from '../../router/ProtectedRoute';
import MUIDataTable from 'mui-datatables';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/contextProvider';
import useAuth from '../../auth/useAuth';

const Clients = () => {
  const { checkResponse, session } = useAuth();
  const { selectedStore } = useAppContext();
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Clientes';
  }, []);

  useEffect(() => {
    handleGetClients();
  }, []);

  const handleGetClients = async () => {
    try {
      // const response = (await getProducts()) || false;
      const response =
        (await getClientsByStoreId(session.accessToken, selectedStore._id)) ||
        false;

      if (checkResponse(response)) {
        setClients(response.data);
        console.log('clients: ', response.data);
      }
    } catch (e) {
      console.log('error at handleGetClients: ', e);
    }
  };

  const handleRowClick = (rowData, rowMeta) => {
    // rowData contains the data of the clicked row
    // rowMeta contains additional information about the row
    const clientId = clients[rowMeta.dataIndex]._id; // Adjust this line based on your actual data structure

    // Check your condition to redirect
    if (clientId) {
      // Redirect to /orders/orderId
      navigate(`/clients/${clientId}`);
    }
  };

  const columns = [
    {
      name: 'userEmail',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value,
      },
    },
    {
      name: 'userName',
      label: 'Nombre',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value,
      },
    },
    {
      name: 'userLastName',
      label: 'Apellido',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value,
      },
    },
    {
      name: 'orderCount',
      label: 'Órdenes',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value,
      },
    },
    {
      name: 'totalAmount',
      label: 'Total en ventas',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          // Check if 'value' is a valid number
          const formattedValue =
            typeof value === 'number'
              ? `$ ${new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 2,
                }).format(value.toFixed(2))}`
              : '';

          return (
            <div style={{ textAlign: 'right', paddingRight: '16px' }}>
              {formattedValue}
            </div>
          );
        },
        customHeadRender: (columnMeta) => (
          <th style={{ textAlign: 'right', paddingRight: '32px' }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    rowsPerPage: 15,
    onRowClick: handleRowClick,
  };

  return (
    <ProtectedRoute>
      <div
        style={{
          padding: 20,
        }}
      >
        <div
          style={{
            backgroundColor: '#eef2f6',
            width: '100%',
            height: '100%',
            minHeight: 'calc(100vh - 40px)',
            borderRadius: 10,
            padding: 20,
          }}
        >
          <MUIDataTable
            title={'Clientes'}
            data={clients}
            columns={columns}
            options={options}
            // style={{ boxShadow: 'none' }}
            className='mui-datatables-container'
          />
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default Clients;

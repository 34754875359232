import { createContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { userAuth } from '../api/auth';
import Swal from 'sweetalert2';

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [session, setSession] = useState(() => {
    const storedSession = localStorage.getItem('session');
    return storedSession !== 'undefined' ? JSON.parse(storedSession) : null;
  });
  const handleLogin = async (email, password) => {
    let userData = JSON.stringify({
      email: email,
      password: password,
    });

    try {
      const response = await userAuth(userData);
      if (response.success) {
        const session = response.data;

        console.log('session on auth: ', session);
        console.log('typeof: ', typeof session);

        setSession(session);
        localStorage.setItem('session', JSON.stringify(session));

        navigate('/admin');
        const origin = location.state?.from?.pathname || '/admin';
        navigate(origin);
      } else {
        console.log('you shall not pass!');
        Swal.fire({
          icon: 'error',
          title: 'Usuario o contraseña incorrectos',
          confirmButtonColor: '#DD6B55',
        });
      }
    } catch (err) {
      console.error('Login failed:', err);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    setSession(null);
  };

  const handleApiResponse = (response) => {
    console.log('checking response: ', response);
    if (!response.success) {
      if (
        response.message === 'jwt expired' ||
        response.message === 'Token is invalid' ||
        response.message === 'jwt malformed'
      ) {
        // Log out if JWT has expired
        console.log('Logging out: ', response.message);
        handleLogout();
      } else {
        console.log('API call failed with message: ', response.message);
      }
      return false; // Indicate that the API call was not successful
    }
    console.log('API call is a success: ', response.message);
    return true; // Indicate that the API call was successful
  };

  const value = {
    session,
    onLogin: handleLogin,
    onLogout: handleLogout,
    checkResponse: handleApiResponse,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

import React, { useEffect, useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import theme from '../styles';
// import logoPLR from '../resources/logos/plr-logo2.svg';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { resetPassword } from '../../api/user';
import PublicRoute from '../../router/PublicRoute';

export default function ResetPassword() {
  // const classes = useStyles();

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };

    // Add event listener to update screen size when window is resized
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  //Particular Hooks
  const [password, setPassword] = useState('');
  const [focusPassword1, setFocusedPassword1] = useState(false);
  const [passwordVisible1, setPasswordVisible1] = useState(false);
  const [password2, setPassword2] = useState('');
  const [focusPassword2, setFocusedPassword2] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  //eslint-disable-next-line
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d][\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,}$/;
  // const passwordRegex1 = /^(?=.*\d)(?=.*[a-zA-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,}$/;

  useEffect(() => {
    document.title = 'Restablecer mi Contraseña - Luziapp';
  }, []);

  useEffect(() => {
    sessionStorage['inResetPassword'] = true;
  }, []);

  URLValidation();

  function URLValidation() {
    if (!urlParams.has('token')) {
      window.location.href = '/';
    }
  }

  async function changePassword(e) {
    e.preventDefault();
    try {
      if (!process.env.REACT_APP_API_URL) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un error por nuestra parte',
        });
        console.log('Error 404: API URL not found.');
        return;
      }

      if (password.match(passwordRegex) === null) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'La contraseña debe contener al menos una letra y un número',
        });
        return;
      }

      if (password.length < 8) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'La contraseña debe tener al menos 8 caracteres',
        });
        return;
      }

      if (password !== password2) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Las contraseñas deben coincidir',
        });
        return;
      }

      var data = JSON.stringify({
        token: token,
        newPassword: password.trim(),
      });

      const response = (await resetPassword(data)) || false;
      console.log('response: ', response);

      if (!response.success) {
        Swal.fire({
          icon: 'error',
          title: 'Ups...',
          text: 'Hubo un error',
        });
        return;
      }

      Swal.fire('¡Éxito!', 'Contraseña Actualizada Correctamente', 'success');
    } catch (error) {
      console.log('error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Ups...',
        text: error,
      });
    }
  }

  return (
    <PublicRoute>
      <div
        style={{
          overflow: 'hidden',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxSizing: 'border-box',
          width: '100vw',
          height: 'auto',
          justifyContent: 'space-evenly',
          padding: screenSize.width > 600 ? '20px 90px' : '0px 10px',
        }}
      >
        <div
          style={{
            margin: '0 auto',
            color: '#2e186a',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            height: '60vh',
          }}
        >
          <h1
            style={{
              fontSize: 48,
              fontWeight: 600,
              textTransform: 'none',
              color: '#2e186a',
              padding: 0,
              margin: 0,
              textAlign: 'center',
            }}
          >
            Restablecer Contraseña
          </h1>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width:
                screenSize.width > 1280
                  ? '30vw'
                  : screenSize.width > 600
                  ? '70%'
                  : '100%',
            }}
            onSubmit={(e) => changePassword(e)}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                // padding: '10px',
                border: `2px solid #2e186a`,
                borderRadius: '30px',
                margin: '10px',
              }}
            >
              <input
                style={{
                  backgroundColor: 'transparent',
                  color: '#2e186a',
                  padding: '16px',
                  cursor: 'pointer',
                  // border: `2px solid #2e186a`,
                  // boxSizing: 'border-box',
                  width: '100%',
                  fontWeight: 500,
                  textAlign: 'center',
                  fontSize: '16px',
                  // margin: '10px',
                  // borderRadius: '30px',
                  '&:focus': {
                    outline: 'none !important',
                  },
                  '&::placeholder': {
                    color: '#2e186a',
                  },
                }}
                type={passwordVisible1 ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setFocusedPassword1(true)}
                onBlur={() => setFocusedPassword1(false)}
                placeholder={focusPassword1 ? '' : 'Nueva Contraseña'}
              />
              <div
                onClick={() => setPasswordVisible1(!passwordVisible1)}
                style={{
                  backgroundColor: 'transparent',
                  borderBox: 'border-box',
                  color: '#000000',
                  cursor: 'pointer',
                  border: `0px solid`,
                  padding: '15px',
                }}
              >
                <FontAwesomeIcon
                  icon={passwordVisible1 ? faEyeSlash : faEye}
                  style={{
                    color: '#2e186a',
                    animationDuration: '5s',
                  }}
                  size='lg'
                  onClick={() => setPasswordVisible1(!passwordVisible1)}
                />{' '}
              </div>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                // padding: '10px',
                border: `2px solid #2e186a`,
                borderRadius: '30px',
                margin: '10px',
              }}
            >
              <input
                style={{
                  backgroundColor: 'transparent',
                  color: '#2e186a',
                  padding: '16px',
                  cursor: 'pointer',
                  // border: `2px solid #2e186a`,
                  // boxSizing: 'border-box',
                  width: '100%',
                  fontWeight: 500,
                  textAlign: 'center',
                  fontSize: '16px',
                  // margin: '10px',
                  // borderRadius: '30px',
                  '&:focus': {
                    outline: 'none !important',
                  },
                  '&::placeholder': {
                    color: '#2e186a',
                  },
                }}
                type={passwordVisible2 ? 'text' : 'password'}
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                onFocus={() => setFocusedPassword2(true)}
                onBlur={() => setFocusedPassword2(false)}
                placeholder={focusPassword2 ? '' : 'Confirmar Nueva Contraseña'}
              />
              <div
                onClick={() => setPasswordVisible2(!passwordVisible2)}
                style={{
                  backgroundColor: 'transparent',
                  borderBox: 'border-box',
                  color: '#000000',
                  cursor: 'pointer',
                  border: `0px solid`,
                  padding: '15px',
                }}
              >
                <FontAwesomeIcon
                  style={{
                    color: '#2e186a',
                    animationDuration: '5s',
                  }}
                  icon={passwordVisible2 ? faEyeSlash : faEye}
                  size='lg'
                />
              </div>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 10,
              }}
            >
              <input
                style={{
                  backgroundColor: '#2e186a',
                  borderBox: 'border-box',
                  width: '100%',
                  color: '#FFFFFF',
                  cursor: 'pointer',
                  border: `2px solid #2e186a`,
                  fontWeight: 500,
                  textAlign: 'center',
                  fontSize: '16px',
                  padding: '10px',
                  borderRadius: '30px',
                }}
                type={'submit'}
                value={'Guardar Contraseña'}
              />
            </div>
          </form>
        </div>
      </div>
    </PublicRoute>
  );
}

// OrderDetail.js
import { useParams } from 'react-router-dom';
import ProtectedRoute from '../../router/ProtectedRoute';
import useAuth from '../../auth/useAuth';
import { getOrderDetail } from '../../api/orderDetail';
import { useEffect, useState } from 'react';

const OrderDetail= () => {
  const { session } = useAuth();
  const { orderId } = useParams(); // Get orderId from URL parameter
  const [orderDetails, setOrderDetails] = useState([]);
  const [shippingInfo, setShippingInfo] = useState([]);
 
  useEffect(() => {
    handleGetOrder();
  }, []);

  const handleGetOrder = async () => {
    try {
      const response = (await getOrderDetail(session.accessToken, orderId)) || false;
      if (response.success) {
        setOrderDetails(response.data);
        setShippingInfo(response.data.shippingInfo);
      }
    } catch (e) {
      console.log('error: ', e);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('es-MX', options);
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'PENDING_TO_SHIP':
        return 'Pendiente de entregar';
      case 'COMPLETED':
        return 'Entregado';
      case 'CANCELLED':
        return 'Cancelado';
      case 'RETURNED':
        return 'Devuelto';
      default:
        return status; // or return a default text if none of the above matches
    }
  };
  // Fetch order details using orderId
  // Example: const orderDetails = fetchOrderDetails(orderId);

  return (
    <ProtectedRoute>
      <div
        style={{
          padding: 20,
        }}
      >
        <div
          style={{
            backgroundColor: '#eef2f6',
            width: '100%',
            height: '100%',
            minHeight: 'calc(100vh - 40px)',
            borderRadius: 10,
            padding: 20,
          }}
        >
          {/* Display order details */}
          <h2>Detalles para la orden #{orderDetails.order}</h2>
          <p>{orderDetails.order}</p>
          <p>{orderDetails.totalAmount}</p>
          <p>{getStatusText(orderDetails.deliveryStatus)}</p>
          <p>{formatDate(orderDetails.createdAt)}</p>
          <p>Informacion de envio</p>
          <p>{shippingInfo.city}</p>
          <p>{shippingInfo.neighbourhood}</p>
          <p>{shippingInfo.notes}</p>
          <p>#{shippingInfo.number}</p>
          <p>{shippingInfo.street}</p>
          {/* Display other order details based on orderDetails */}
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default OrderDetail;

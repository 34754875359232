// eslint-disable-next-line no-unused-vars
import ProtectedRoute from '../../router/ProtectedRoute';
import { getOrders, getOrdersByStoreId } from '../../api/orders';
import { useEffect, useState } from 'react';
import useAuth from '../../auth/useAuth';
import MUIDataTable from 'mui-datatables';
import './style.css';
import { Button } from '../../common/Button';
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../context/contextProvider';

const Orders = () => {
  const { checkResponse, session } = useAuth();
  const { selectedStore } = useAppContext();
  const [orderData, setOrderData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    handleGetOrder();
  }, []);

  useEffect(() => {
    document.title = 'Pedidos';
  }, []);

  const handleGetOrder = async () => {
    console.log('token', session.accessToken);
    try {
      const response = (await getOrdersByStoreId(session.accessToken, selectedStore._id)) || false;
      if (checkResponse(response)) {
        setOrderData(response.data);
        console.log(response.data)
      }
    } catch (e) {
      console.log('error: ', e);
    }
  };

  const [sortOrder, setSortOrder] = useState('desc');

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('es-MX', options);
  };

  const sortedOrderData = [...orderData].sort((a, b) => {
    const valueA = a.order;
    const valueB = b.order;

    if (sortOrder === 'asc') {
      return valueA - valueB;
    } else {
      return valueB - valueA;
    }
  });

  const getStatusText = (status) => {
    switch (status) {
      case 'PENDING_TO_SHIP':
        return 'Pendiente de entregar';
      case 'COMPLETED':
        return 'Entregado';
      case 'CANCELLED':
        return 'Cancelado';
      case 'RETURNED':
        return 'Devuelto';
      default:
        return status; // or return a default text if none of the above matches
    }
  };

  const handleRowClick = (rowData, rowMeta) => {
    // rowData contains the data of the clicked row
    // rowMeta contains additional information about the row
    const orderId = sortedOrderData[rowMeta.dataIndex]._id; // Adjust this line based on your actual data structure
    console.log('order clicked: ', orderId);

    // Check your condition to redirect
    if (orderId) {
      // Redirect to /orders/orderId
      navigate(`/orders/${orderId}`);
    }
  };

  const columns = [
    {
      name: 'order',
      label: 'Número',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta) => (
          <div style={{ padding: '16px' }}>{columnMeta.label}</div>
        ),
      },
    },
    {
      name: 'createdAt',
      label: 'Fecha',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    {
      name: 'deliveryStatus',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => getStatusText(value),
      },
    },
    {
      name: 'totalAmount',
      label: 'Total',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          // You can customize the rendering of 'totalAmount' here
          // For example, you can add a currency symbol or format the number
          <div style={{ textAlign: 'right', paddingRight: '16px' }}>
            {' '}
            {`$ ${new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 2,
            }).format(value.toFixed(2))}`}
          </div>
        ),
        customHeadRender: (columnMeta) => (
          <th style={{ textAlign: 'right', paddingRight: '32px' }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    rowsPerPage: 15,
    onRowClick: handleRowClick,
    customToolbar: () => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button onClick={toggleSortOrder}>
            {' '}
            Pedido: {sortOrder === 'asc' ? 'Más antiguo' : 'Más reciente'}
          </Button>
        </div>
      );
    },
  };

  return (
    <ProtectedRoute>
      <div
        style={{
          padding: 20,
        }}
      >
        <div
          style={{
            backgroundColor: '#eef2f6',
            width: '100%',
            height: '100%',
            minHeight: 'calc(100vh - 40px)',
            borderRadius: 10,
            padding: 20,
          }}
        >
          <MUIDataTable
            title={'Pedidos'}
            data={sortedOrderData}
            columns={columns}
            options={options}
            // style={{ boxShadow: 'none' }}
            className='mui-datatables-container'
          />
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default Orders;

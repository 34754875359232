import { lazy, useEffect, useState } from 'react';
import { TermsContent } from '../../assets/documents/termsContent';
import { getTermsAndConditions } from '../../api/termsAndConditions';
import FormattedContent from '../../components/FormattedContent';
import PublicRoute from '../../router/PublicRoute';

const Container = lazy(() => import('../../common/Container'));
const ScrollToTop = lazy(() => import('../../common/ScrollToTop'));

interface Content {
  Title: string;
  Content: string;
  // Add other properties as needed
}

const TermsAndConditions = () => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState<Content | undefined>(undefined);
  useEffect(() => {
    document.title = 'Términos y condiciones - Luziapp';
    handleContent();
  }, []);

  const handleContent = async () => {
    try {
      const response = (await getTermsAndConditions()) || false;

      setContent(response.data.attributes);
      setLoading(false);
    } catch (e) {
      console.log('error');
    }
  };

  return (
    <PublicRoute>
      <Container>
        <ScrollToTop />
        <h2 id='intro'>{content?.Title}</h2>
        <br />
        <FormattedContent content={content?.Content} />
        {/* {Object.entries(TermsContent).map(([key, value]) => (
        <div key={key} id='intro'>
          <p>{value.title}</p>
          <p>{value.content}</p>
        </div>
      ))} */}
      </Container>
    </PublicRoute>
  );
};

export default TermsAndConditions;

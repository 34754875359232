//Creates a receipt (uploads it) with given data by the user.
async function getPrivacyPolicy() {
  try {
    let requestOptions = {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_CMS_ACCESS_TOKEN}`,
      },
      redirect: 'follow',
    };

    const response = await fetch(
      `${process.env.REACT_APP_CMS_URL}/api/luziapp-privacy-policy`,
      requestOptions,
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
}

export {getPrivacyPolicy};

import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
// import Footer from "../components/Footer";
// import Header from "../components/Header";
import { Styles } from '../styles/styles';

import Home from '../pages/Home';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Tienda from '../pages/Tienda';
import TermsAndConditions from '../pages/TermsAndConditions';
import ResetPassword from '../pages/ResetPassword';
import Admin from '../pages/Admin';
import Orders from '../pages/Orders';
import OrderDetail from '../pages/OrderDetail';
import Marketing from '../pages/Marketing';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import Products from '../pages/Products';
import ProductDetail from '../pages/ProductDetail';
import Clients from '../pages/Clients';
import ClientDetail from '../pages/ClientDetail';
import Profile from '../pages/Profile';

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      {/* <Header /> */}
      <Routes>
        <Route index element={<Home />}></Route>
        <Route path='home' element={<Home />}></Route>
        <Route path='privacyPolicy' element={<PrivacyPolicy />}></Route>
        <Route
          path='termsAndConditions'
          element={<TermsAndConditions />}
        ></Route>
        <Route path='tienda' element={<Tienda />}></Route>
        <Route path='recuperar-cuenta' element={<ResetPassword />}></Route>
        <Route path='admin' element={<Admin />}></Route>
        <Route path='profile' element={<Profile />}></Route>
        <Route path='orders' element={<Orders />}></Route>
        <Route path="orders/:orderId" element={<OrderDetail />} />
        <Route path='products' element={<Products />}></Route>
        <Route path="products/:productId" element={<ProductDetail />} />
        <Route path='clients' element={<Clients />}></Route>
        <Route path="cleintes/:clientId" element={<ClientDetail />} />
        <Route path='marketing' element={<Marketing />}></Route>
        <Route path='login' element={<Login />}></Route>
        <Route path='*' element={<NotFound />}></Route>
      </Routes>
      {/* <Footer /> */}
    </Suspense>
  );
};

export default Router;

import { lazy, useEffect, useState } from 'react';
import IntroContent from '../../content/IntroContent.json';
import MiddleBlockContent from '../../content/MiddleBlockContent.json';
import AboutContent from '../../content/AboutContent.json';
import MissionContent from '../../content/MissionContent.json';
import ProductContent from '../../content/ProductContent.json';
import ContactContent from '../../content/ContactContent.json';
import { getDownloadLinks } from '../../api/downloadLinks';
import { getSectionB } from '../../api/sectionB';
import { getSectionC } from '../../api/sectionC';
import { getSectionD } from '../../api/sectionD';
import { getContactText } from '../../api/contactText';
import PublicRoute from '../../router/PublicRoute';

const Contact = lazy(() => import('../../components/ContactForm'));
const MiddleBlock = lazy(() => import('../../components/MiddleBlock'));
const Container = lazy(() => import('../../common/Container'));
const ScrollToTop = lazy(() => import('../../common/ScrollToTop'));
const ContentBlock = lazy(() => import('../../components/ContentBlock'));
const HeroSection = lazy(() => import('../../components/HeroSection'));

interface Content {
  Title: string;
  IOS: string;
  Android: string;
}

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState<Content>({
    Title: '',
    IOS: '',
    Android: '',
  });
  const [contentSectionB, setContentSectionB] = useState({
    Title: '',
    Description: '',
    QuestionAndAnswer: [],
  });
  const [contentSectionC, setContentSectionC] = useState({
    Title: '',
    IOS: '',
    Android: '',
  });
  const [contentSectionD, setContentSectionD] = useState({
    Title: '',
    Description: '',
  });
  const [contactText, setContactText] = useState({
    Title: '',
    Description: '',
  });
  const [imageURLSectionB, setImageURLSectionB] = useState('');
  const [imageURLSectionC, setImageURLSectionC] = useState('');
  const [imageURLSectionD, setImageURLSectionD] = useState('');
  // const [contentContact, setContentContact] = useState({
  //   Title: '',
  //   IOS: '',
  //   Android: '',
  // });

  useEffect(() => {
    document.title = 'Luziapp - Conoce tu recibo';
    handleContent();
  }, []);

  const handleContent = async () => {
    try {
      const responseDownloadLinks = (await getDownloadLinks()) || false;
      const responseSectionB = (await getSectionB()) || false;
      const responseSectionC = (await getSectionC()) || false;
      const responseSectionD = (await getSectionD()) || false;
      const responseContactText = (await getContactText()) || false;

      setContent(responseDownloadLinks.data.attributes);
      setContentSectionB(responseSectionB.data.attributes);
      setContentSectionC(responseSectionC.data.attributes);
      setContentSectionD(responseSectionD.data.attributes);
      setContactText(responseContactText.data.attributes);

      const sectionBImage =
        responseSectionB.data.attributes.Image.data.attributes.url;
      setImageURLSectionB(sectionBImage);

      const sectionCImage =
        responseSectionC.data.attributes.Image.data.attributes.url;
      setImageURLSectionC(sectionCImage);

      const sectionDImage =
        responseSectionD.data.attributes.Image.data.attributes.url;
      setImageURLSectionD(sectionDImage);

      setLoading(false);
    } catch (e) {
      console.log('error');
    }
  };

  return (
    <PublicRoute>
      <Container>
        <ScrollToTop />
        <HeroSection
          type='right'
          title={IntroContent.title}
          description={IntroContent.text}
          button={IntroContent.button}
          image='iphone-3d.png'
          id='intro'
          downloadInfo={content}
        />
        <MiddleBlock
          title={MiddleBlockContent.title}
          description={MiddleBlockContent.text}
          button={MiddleBlockContent.button}
          link1={MiddleBlockContent.link1}
          link2={MiddleBlockContent.link2}
          downloadInfo={content}
        />
        <ContentBlock
          type='left'
          title={AboutContent.title}
          description={AboutContent.text}
          section={AboutContent.section}
          icon='graphs.svg'
          id='about'
          sectionB={contentSectionB}
          imageURL={imageURLSectionB}
        />
        <ContentBlock
          type='right'
          title={MissionContent.title}
          description={MissionContent.text}
          icon='product-launch.svg'
          id='mission'
          sectionB={contentSectionC}
          imageURL={imageURLSectionC}
        />
        <ContentBlock
          type='left'
          title={ProductContent.title}
          description={ProductContent.text}
          icon='waving.svg'
          id='product'
          sectionB={contentSectionD}
          imageURL={imageURLSectionD}
        />
        <Contact
          title={ContactContent.title}
          content={ContactContent.text}
          id='contact'
          contactText={contactText}
        />
      </Container>
    </PublicRoute>
  );
};

export default Home;

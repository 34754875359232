import styled from 'styled-components';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 10px;
  background-color: #18216d;
  border-radius: 4px;
`;

export const StyledSelect = styled('select')`
  width: 100%;
  margin: 7px 0 0;
  font-size: 1rem;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
`;

export const StyledText = styled('text')`
  width: 100%;
  color: white;
  font-size: 1rem;
`;

// Import necessary libraries and components
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, InputContainer, StyledInput, ToggleButton } from './styles'; // Add ToggleButton import
import { Label } from '../TextArea/styles';
import { InputProps } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const PasswordInput = ({ name, placeholder, onChange, t }: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container>
      <Label htmlFor={name}>{t(name)}</Label>
      <InputContainer>
        <StyledInput
          type={showPassword ? 'text' : 'password'}
          placeholder={t(placeholder)}
          name={name}
          id={name}
          onChange={onChange}
        />
        <ToggleButton onClick={handleTogglePassword}>
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            style={{
              color: '#2e186a',
            }}
            size='lg'
            onClick={handleTogglePassword}
          />
        </ToggleButton>
      </InputContainer>
    </Container>
  );
};

export default withTranslation()(PasswordInput);

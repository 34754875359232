// eslint-disable-next-line no-unused-vars
import { useEffect, useState } from 'react';
import ProtectedRoute from '../../router/ProtectedRoute';
import { useAppContext } from '../../context/contextProvider';
import useAuth from '../../auth/useAuth';
import { getStoreByStoreId } from '../../api/stores';

const Admin = () => {
  const { checkResponse, session } = useAuth();
  const { selectedStore } = useAppContext();
  const [store, setStore] = useState();

  useEffect(() => {
    document.title = 'Perfil de tienda';
  }, []);

  useEffect(() => {
    handleGetStore();
  }, []);

  const handleGetStore = async () => {
    try {
      // const response = (await getProducts()) || false;
      const response =
        (await getStoreByStoreId(session.accessToken, selectedStore._id)) ||
        false;

      if (checkResponse(response)) {
        setStore(response.data);
        console.log('store: ', response.data);
      }
    } catch (e) {
      console.log('error at handleGetStore: ', e);
    }
  };

  return (
    <ProtectedRoute>
      <div
        style={{
          padding: 20,
        }}
      >
        <div
          style={{
            backgroundColor: '#eef2f6',
            width: '100%',
            height: '100%',
            minHeight: 'calc(100vh - 40px)',
            borderRadius: 10,
            padding: 20,
          }}
        >
          {store && <p>Nombre de tienda: {store.name}</p>}
          {store && <p>Dirección: {store.address}</p>}
          {store && <p>Descripción: {store.description}</p>}
          {store && <p>Nombre legal: {store.legalName}</p>}
          {store && <p>RFC: {store.rfc}</p>}
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default Admin;

//Returns all users. (Used at Profile)
async function getAllReceipts(accessToken) {
  try {
    let requestOptions = {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'User-Agent': 'app'
      },
      redirect: 'follow'
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/receipts`, requestOptions);

    return response.json();
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export { getAllReceipts };

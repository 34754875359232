//Each function that has accessToken as an argument, needs to be executed with a recent user login. Otherwise, it will not work.
//A unique accessToken is generated whenever the user is authorized.

//Creates a receipt (uploads it) with given data by the user.
async function getProducts() {
  try {
    let requestOptions = {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_MARKETPLACE_API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'User-Agent': 'app',
      },
      redirect: 'follow',
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/marketplace/api/products`,
      requestOptions
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function getProductsByStoreId(accessToken, storeId) {
  try {
    let requestOptions = {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_MARKETPLACE_API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'User-Agent': 'app',
      },
      redirect: 'follow',
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/marketplace/api/products/stores/${storeId}`,
      requestOptions
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function updateProduct(accessToken, productId, productData) {
  try {
    let requestOptions = {
      method: 'PUT',
      headers: {
        'x-api-key': process.env.REACT_APP_MARKETPLACE_API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'User-Agent': 'app',
      },
      body: JSON.stringify({ data: productData }),
      redirect: 'follow',
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/marketplace/api/products/${productId}`,
      requestOptions
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
}

export { getProducts, getProductsByStoreId, updateProduct };

import { getProducts, getProductsByStoreId } from '../../api/products';
import { useEffect, useState } from 'react';
import ProtectedRoute from '../../router/ProtectedRoute';
import MUIDataTable from 'mui-datatables';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/contextProvider';
import useAuth from '../../auth/useAuth';

const Products = () => {
  const { checkResponse, session } = useAuth();
  const { selectedStore } = useAppContext();
  const [products, setProducts] = useState([]);
  const [productsWithImage, setProductsWithImage] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Productos';
  }, []);

  useEffect(() => {
    handleGetProducts();
  }, []);

  useEffect(() => {
    handleGetProductsWithImage();
  }, [products]);

  const handleGetProducts = async () => {
    try {
      // const response = (await getProducts()) || false;
      const response =
        (await getProductsByStoreId(session.accessToken, selectedStore._id)) ||
        false;

      if (checkResponse(response)) {
        setProducts(response.data);
        console.log('products: ', response.data);
      }
    } catch (e) {
      console.log('error at handleGetProducts: ', e);
    }
  };

  const handleGetProductsWithImage = () => {
    if (products) {
      const productsWithImage = products.map((product) => ({
        image: product.cover, // Assuming the product ID is in the first column
        name: product.name,
        category: product.category,
        quantity: product.quantity,
        price: product.price,
      }));
      setProductsWithImage(productsWithImage);
    }

  };

  const handleRowClick = (rowData, rowMeta) => {
    // rowData contains the data of the clicked row
    // rowMeta contains additional information about the row
    const productId = products[rowMeta.dataIndex]._id; // Adjust this line based on your actual data structure

    // Check your condition to redirect
    if (productId) {
      // Redirect to /orders/orderId
      navigate(`/products/${productId}`);
    }
  };

  const columns = [
    {
      name: 'image',
      label: 'Imagen',
      options: {
        customBodyRender: (image) => (
          <img
            src={`${process.env.REACT_APP_API_URL}/marketplace/api/files/${image}`}
            alt='producto'
            style={{
              width: 30,
              height: 30,
              borderRadius: 15,
              border: '2px solid purple', // Add a purple border
            }}
          />
        ),
      },
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value,
      },
    },
    {
      name: 'category',
      label: 'Categoria',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value,
      },
    },
    {
      name: 'quantity',
      label: 'Inventario',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value,
      },
    },
    {
      name: 'price',
      label: 'Total',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          // Check if 'value' is a valid number
          const formattedValue =
            typeof value === 'number'
              ? `$ ${new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 2,
                }).format(value.toFixed(2))}`
              : '';

          return (
            <div style={{ textAlign: 'right', paddingRight: '16px' }}>
              {formattedValue}
            </div>
          );
        },
        customHeadRender: (columnMeta) => (
          <th style={{ textAlign: 'right', paddingRight: '32px' }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    rowsPerPage: 15,
    onRowClick: handleRowClick,
  };

  return (
    <ProtectedRoute>
      <div
        style={{
          padding: 20,
        }}
      >
        <div
          style={{
            backgroundColor: '#eef2f6',
            width: '100%',
            height: '100%',
            minHeight: 'calc(100vh - 40px)',
            borderRadius: 10,
            padding: 20,
          }}
        >
          <MUIDataTable
            title={'Productos'}
            data={productsWithImage}
            columns={columns}
            options={options}
            // style={{ boxShadow: 'none' }}
            className='mui-datatables-container'
          />
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default Products;

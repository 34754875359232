// OrderDetail.js
import { useParams } from 'react-router-dom';
import ProtectedRoute from '../../router/ProtectedRoute';
import useAuth from '../../auth/useAuth';
import { getProductDetail } from '../../api/productDetail';
import { updateProduct } from '../../api/products';
import { useEffect, useState } from 'react';

const ProductDetail = () => {
  const { session } = useAuth();
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState([]);

  useEffect(() => {
    handleGetProductDetail();
  }, []);

  const handleGetProductDetail = async () => {
    try {
      const response =
        (await getProductDetail(session.accessToken, productId)) || false;
      if (response.success) {
        setProductDetails(response.data);
      }
    } catch (e) {
      console.log('error: ', e);
    }
  };

  const [isEditMode, setIsEditMode] = useState(false);
  const [editedProductData, setEditedProductData] = useState({});

  const handleProductUpdate = async () => {
    try {
      const response = await updateProduct(session.accessToken, productId, editedProductData) || false;

      if (response.success) {
        setProductDetails(response.data);
        console.log('Update successfully');
        handleGetProductDetail();
        setIsEditMode(false);
      }
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedProductData(productDetails);
  };

  const handleInputChange = (e) => {
    setEditedProductData({
      ...editedProductData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <ProtectedRoute>
      <div style={{ padding: 20 }}>
        <div style={{ backgroundColor: '#eef2f6', width: '100%', height: '100%', minHeight: 'calc(100vh - 40px)', borderRadius: 10, padding: 20 }}>
          {isEditMode ? (
            <>
              <label>Name</label>
              <input type="text" name="name" value={editedProductData.name} onChange={handleInputChange} />
              <label>Description</label>
              <input type="text" name="description" value={editedProductData.description} onChange={handleInputChange} />
              <label>Categoria</label>
              <input type="text" name="category" value={editedProductData.category} onChange={handleInputChange} />
              <label>Quantity</label>
              <input type="text" name="quantity" value={editedProductData.quantity} onChange={handleInputChange} />
              <label>Price</label>
              <input type="text" name="price" value={editedProductData.price} onChange={handleInputChange} />
              <button onClick={handleProductUpdate}>Save</button>
            </>
          ) : (
            <>
              <h2>Product ID: {productDetails._id}</h2>
              <h2>Detalles para el producto: {productDetails.name}</h2>
              <p>{productDetails.description}</p>
              <p>Categoria {productDetails.category}</p>
              <p>Inventario {productDetails.quantity}</p>
              <p>Precio ${productDetails.price}</p>
              <button onClick={handleEditClick}>Edit</button>
            </>
          )}
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default ProductDetail;

//All PaymentMethods API's are found in this document
 
//Each function that has accessToken as an argument, needs to be executed with a recent user login. Otherwise, it will not work.
//A unique accessToken is generated whenever the user is authorized.

//getCart returns all cards linked to the user's account. (Used in PaymentMethods)

async function getProductDetail(accessToken, productId) {
    try {
        let requestOptions = {
          method: 'GET',
          headers: {
            'x-api-key': process.env.REACT_APP_MARKETPLACE_API_KEY,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'User-Agent': 'app',
          },
          redirect: 'follow',
        };
    
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/marketplace/api/products/${productId}`,
          requestOptions
        );
        return await response.json();
      } catch (error) {
        throw error;
      }
    }
    
  
  export { getProductDetail };
const GoTo = (location) => {
  if (window.location.pathname !== "/") {
    window.location.href = "/";
    return; // Exit the function to allow the page to refresh
  }
  
  const element = document.getElementById(location);
  element.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  });
};

export default GoTo;

import { useEffect, useState } from 'react';
import { useAppContext } from '../../context/contextProvider';
import { withTranslation } from 'react-i18next';
import { Container, StyledSelect, StyledText } from './styles';
import { Label } from '../TextArea/styles';
import { DropdownProps } from '../types';

const Dropdown = ({
  name,
  placeholder,
  onChange,
  t,
  options,
}: DropdownProps) => {
  const { storeId, setStoreId } = useAppContext();
  const [selectedValue, setSelectedValue] = useState(storeId || '');

  useEffect(() => {
    setSelectedValue(storeId || '');
  }, [storeId]);

  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedValue = event.target.value;
    setStoreId(selectedValue);
    setSelectedValue(selectedValue);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Container>
      <StyledText>{name} </StyledText>
      <StyledSelect onChange={handleSelectChange} value={selectedValue}>
        {options &&
          options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
      </StyledSelect>
    </Container>
  );
};

export default withTranslation()(Dropdown);

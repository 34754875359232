import { lazy, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import { SvgIcon } from '../../common/SvgIcon';
import Container from '../../common/Container';
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  InvisibleButton,
  Label,
  Large,
} from './styles';
import GoTo from '../../common/utils/goTo';
import { getFooterInfo } from '../../api/footerInfo';

const Footer = ({ t }: any) => {
  const [footerInfo, setFooterInfo] = useState({
    Direccion: '',
    Telefono: '',
    Email: '',
  });
  useEffect(() => {
    handleContent();
  }, []);

  const handleContent = async () => {
    try {
      const responseFooterInfo = (await getFooterInfo()) || false;
      setFooterInfo(responseFooterInfo.data.attributes);
      console.log('responsefooterinfo: ', responseFooterInfo.data.attributes);
    } catch (e) {
      console.log('error');
    }
  };

  return (
    <>
      <FooterSection>
        <Extra>
          <Container>
            <Row justify='center' align='middle'>
              <NavLink to='/'>
                <LogoContainer>
                  <InvisibleButton onClick={() => GoTo('intro')}>
                    <img
                      src={`${process.env.REACT_APP_CMS_URL}/uploads/luziapp_logotype_6c6eab0969.svg`}
                      aria-label='homepage'
                      width='200px'
                      height='auto'
                    />
                  </InvisibleButton>
                </LogoContainer>
              </NavLink>
            </Row>
          </Container>
        </Extra>
        <Container border={true}>
          <Row justify='space-between' style={{ paddingTop: '3rem' }}>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Title>{t('Legal')}</Title>
              <br />
              <Large
                to='/privacyPolicy'
                left='true'
                onClick={() => window.scrollTo(0, 0)}
              >
                {t('Política de privacidad')}
              </Large>
              <Large
                left='true'
                to='/termsAndConditions'
                onClick={() => window.scrollTo(0, 0)}
              >
                {t('Términos y condiciones')}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Title>{t('Información de contacto')}</Title>
              <br />
              <Large left='true'>{t(footerInfo.Direccion)}</Large>
              <Large left='true'>{t(footerInfo.Telefono)}</Large>
              <Large left='true'>{t(footerInfo.Email)}</Large>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);

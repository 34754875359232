// eslint-disable-next-line no-unused-vars
import { useEffect, useState } from 'react';
import ProtectedRoute from '../../router/ProtectedRoute';
import { useAppContext } from '../../context/contextProvider';
import { getClientsByStoreId } from '../../api/clients';
import { getProductsByStoreId } from '../../api/products';
import { getOrdersByStoreId } from '../../api/orders';
import useAuth from '../../auth/useAuth';

const Admin = () => {
  const [loading, setLoading] = useState(true);
  const { checkResponse, session } = useAuth();
  const { selectedStore } = useAppContext();
  const [clients, setClients] = useState([]);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [totalInSells, setTotalInSells] = useState(0);
  const [totalOrdersThisMonth, setTotalOrdersThisMonth] = useState(0);
  const [totalSalesThisMonth, setTotalSalesThisMonth] = useState(0);

  useEffect(() => {
    document.title = 'Dashboard';
  }, []);

  useEffect(() => {
    handleGetClients();
  }, []);

  useEffect(() => {
    handleGetProducts();
  }, []);

  useEffect(() => {
    handleGetOrder();
  }, []);

  useEffect(() => {
    // Calculate total amount from orders array
    const totalAmount = orders.reduce(
      (total, order) => total + order.totalAmount,
      0
    );
    setTotalInSells(totalAmount);

    // Calculate total orders for the current month
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    const ordersThisMonth = orders.filter(
      (order) =>
        new Date(order.createdAt) >= firstDayOfMonth &&
        new Date(order.createdAt) <= lastDayOfMonth
    );

    setTotalOrdersThisMonth(ordersThisMonth.length || 0);

    // Calculate total sales for the current month
    const totalSales = ordersThisMonth.reduce(
      (total, order) => total + order.totalAmount,
      0
    );
    setTotalSalesThisMonth(totalSales);
  }, [orders, totalOrdersThisMonth]);

  const handleGetClients = async () => {
    try {
      // const response = (await getProducts()) || false;
      const response =
        (await getClientsByStoreId(session.accessToken, selectedStore._id)) ||
        false;

      if (checkResponse(response)) {
        setClients(response.data);
        console.log('clients: ', response.data);
      }
    } catch (e) {
      console.log('error at handleGetClients: ', e);
    }
  };

  const handleGetProducts = async () => {
    try {
      const response =
        (await getProductsByStoreId(session.accessToken, selectedStore._id)) ||
        false;

      if (checkResponse(response)) {
        setProducts(response.data);
        console.log('products: ', response.data);
      }
    } catch (e) {
      console.log('error at handleGetProducts: ', e);
    }
  };

  const handleGetOrder = async () => {
    console.log('token', session.accessToken);
    try {
      const response =
        (await getOrdersByStoreId(session.accessToken, selectedStore._id)) ||
        false;
      if (checkResponse(response)) {
        setOrders(response.data);
        console.log(response.data);
      }
    } catch (e) {
      console.log('error: ', e);
    }
  };

  const MetricBox = ({ title, value }) => {
    return (
      <div
        style={{
          backgroundColor: '#18216D',
          width: '33%',
          borderRadius: 10,
          padding: 10,
          maxHeight: 500,
        }}
      >
        <p
          style={{
            color: 'white',
            fontWeight: '100',
          }}
        >
          {title}
        </p>
        <p
          style={{
            color: 'white',
            fontSize: '40px',
            fontWeight: '900',
            textAlign: 'center',
          }}
        >
          {value}
        </p>
      </div>
    );
  };

  return (
    <ProtectedRoute>
      <div
        style={{
          padding: 20,
        }}
      >
        <div
          style={{
            backgroundColor: '#eef2f6',
            width: '100%',
            height: '100%',
            minHeight: 'calc(100vh - 40px)',
            borderRadius: 10,
            padding: 20,
          }}
        >
          {selectedStore && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 10,
                  paddingBottom: 10,
                }}
              >
                {selectedStore && (
                  <MetricBox title='Tu tienda:' value={selectedStore.name} />
                )}
                <MetricBox title='Clientes' value={clients.length || 0} />
                <MetricBox
                  title='Productos publicados:'
                  value={products?.length || 0}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 10,
                  paddingBottom: 10,
                }}
              >
                <MetricBox title='Pedidos:' value={orders.length || 0} />
                <MetricBox
                  title='Pedidos este mes:'
                  value={totalOrdersThisMonth}
                />
                <MetricBox
                  title='Total vendido:'
                  value={`$ ${new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                  }).format(totalInSells.toFixed(2))}`}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 10,
                  paddingBottom: 10,
                }}
              >
                <MetricBox
                  title='Total vendido este mes:'
                  value={`$ ${new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                  }).format(totalSalesThisMonth.toFixed(2))}`}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default Admin;

import { useEffect, useState } from 'react';
import useAuth from '../../auth/useAuth';
import { NavLink } from 'react-router-dom';
import { SecondaryButton } from '../../common/SecondaryButton';
import { getStoresByUserID } from '../../api/stores';
import { useAppContext } from '../../context/contextProvider';
import Dropdown from '../../common/Dropdown';

const style = ({ isActive }) => ({
  fontWeight: isActive ? 'bold' : 'normal',
  fontSize: '24px',
  paddingTop: '10px',
  paddingBottom: '10px',
});

const SideMenu = () => {
  const { checkResponse, onLogout, session } = useAuth();
  const { selectedStore, setSelectedStore } = useAppContext();
  const [stores, setStores] = useState();

  useEffect(() => {
    handleGetStores();
  }, []);

  useEffect(() => {
    console.log('selectedStore:', selectedStore);
  }, [selectedStore]);

  const handleGetStores = async () => {
    try {
      console.log('session on side: ', session);
      const response =
        (await getStoresByUserID(session.accessToken, session._id)) || false;
      if (checkResponse(response)) {
        setStores(response.data);
        console.log('stores: ', response.data);
        // Set the default selected store if there is only one
        console.log('response data: ', response.data);
        if (response.data.length <= 1) {
          setSelectedStore(response.data[0]);
        }
      }
    } catch (e) {
      console.log('error: ', e);
    }
  };
  const dropdownOptions = stores
    ? stores.map((store) => ({
        label: store.name,
        value: store._id,
      }))
    : [];

  const handleDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    // Handle the dropdown change here
    console.log('Dropdown value:', event.target.value);
  };

  return (
    <div
      style={{
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
      }}
    >
      {selectedStore && (
        <div
          style={{
            margin: '0 -20px 15px 0',
          }}
        >
          <Dropdown
            name='Tienda: '
            onChange={handleDropdownChange}
            options={dropdownOptions}
          ></Dropdown>
        </div>
      )}
      <NavLink to='/admin' style={style}>
        Dashboard
      </NavLink>
      {selectedStore && (
        <>
          <NavLink to='/orders' style={style}>
            Pedidos
          </NavLink>
          <NavLink to='/products' style={style}>
            Productos
          </NavLink>
          <NavLink to='/clients' style={style}>
            Clientes
          </NavLink>
          <NavLink to='/profile' style={style}>
            Perfil
          </NavLink>
        </>
      )}
      <NavLink to='/marketing' style={style}>
        Marketing
      </NavLink>
      <div
        style={{
          position: 'absolute',
          bottom: '20px',
          left: '20px',
          right: '0px',
        }}
      >
        <SecondaryButton type='button' onClick={onLogout}>
          Cerrar sesión
        </SecondaryButton>
      </div>
    </div>
  );
};

export default SideMenu;

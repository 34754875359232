import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PublicRoute from '../../router/PublicRoute';

import useAuth from '../../auth/useAuth';
import { Button } from '../../common/Button';
import Input from '../../common/Input';
import PasswordInput from '../../common/PasswordInput';

const Login = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const { onLogin, session } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // If a token exists, redirect the user to /admin
    if (session) {
      navigate('/admin');
    }
  }, [session, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handleLogin = (e) => {
    const { email, password } = credentials;
    onLogin(email, password);
  };
  return (
    <PublicRoute>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '70vh',
          width: '100vw',
        }}
      >
        <div
          style={{
            // backgroundColor: '#eef2f6',
            width: 'auto',
            minWidth: 300,
            height: 'auto',
            borderRadius: 10,
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h6>Inicia sesión</h6>

          <Input
            type='email'
            name='email'
            placeholder='email'
            value={credentials.email}
            onChange={handleChange}
          ></Input>
          <PasswordInput
            class='input2'
            type='password'
            name='password'
            placeholder='contraseña'
            value={credentials.password}
            onChange={handleChange}
          ></PasswordInput>
          <Button type='button' onClick={handleLogin} width='100%'>
            Iniciar sesión
          </Button>
        </div>
      </div>
    </PublicRoute>
  );
};

export default Login;

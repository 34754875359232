// Import necessary libraries and components
import styled from 'styled-components';

export const Container = styled('div')`
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 10px 5px;
`;

export const InputContainer = styled('div')`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 0;
  background: rgb(241, 242, 243);
  transition: all 0.3s ease-in-out;
  outline: none;
  width: 100%;

  :focus-within {
    background: none;
    box-shadow: #2e186a 0px 0px 0px 1px;
  }
`;

export const StyledInput = styled('input')`
  font-size: 0.875rem;
  :focus-within {
    box-shadow: transparent 0px 0px 0px 1px;
  }
`;

export const ToggleButton = styled('button')`
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 0 4px 4px 0;
  padding: 0 10px 0;
`;

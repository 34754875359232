import { BrowserRouter } from 'react-router-dom';
import * as ReactDOMClient from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import 'antd/dist/antd.css';

import Router from './router';
import i18n from './translation';
import AuthProvider from './auth/authProvider';
import { ContextProvider } from './context/contextProvider';

const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <ContextProvider>
          <Router />
        </ContextProvider>
      </AuthProvider>
    </I18nextProvider>
  </BrowserRouter>
);

const container = document.getElementById('root');

if (container) {
  const root = ReactDOMClient.createRoot(container);
  root.render(<App />);
} else {
  console.error('Container element not found!');
}

// OrderDetail.js
import { useParams } from 'react-router-dom';
import ProtectedRoute from '../../router/ProtectedRoute';
import useAuth from '../../auth/useAuth';
import { useEffect, useState } from 'react';
import { getUserByUserId } from '../../api/user';

const ProductDetail = () => {
  const { session } = useAuth();
  const { userId } = useParams(); // Get orderId from URL parameter
  const [client, setClient] = useState();

  useEffect(() => {
    handleGetClient();
  }, []);

  const handleGetClient = async () => {
    try {
      const response =
        (await getUserByUserId(session.accessToken, userId)) || false;
      if (response.success) {
        setClient(response.data);
      }
    } catch (e) {
      console.log('error: ', e);
    }
  };

  return (
    <ProtectedRoute>
      <div
        style={{
          padding: 20,
        }}
      >
        <div
          style={{
            backgroundColor: '#eef2f6',
            width: '100%',
            height: '100%',
            minHeight: 'calc(100vh - 40px)',
            borderRadius: 10,
            padding: 20,
          }}
        >
          {client._id}
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default ProductDetail;

import React from 'react';
import {
  Div
} from './styles';

interface FormattedContentProps {
  content?: string;
}

const FormattedContent: React.FC<FormattedContentProps> = ({
  content = '',
}) => {
  const formatText = (text: string) => {
    // Handle headings (e.g., # Heading)
    text = text.replace(/^(#{1,6})\s(.*)$/gm, (match, hashes, heading) => {
      const level = hashes.length;
      return `<h${level}>${heading}</h${level}>`;
    });
  
    // Handle bold (e.g., **bold**)
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  
    // Handle italic (e.g., _italic_)
    text = text.replace(/_(.*?)_/g, '<em>$1</em>');
  
    // Handle underline (e.g., <u>underline</u>)
    text = text.replace(/<u>(.*?)<\/u>/g, '<u>$1</u>');
  
    // Handle bullet list (e.g., - Item)
    text = text.replace(/^\s*-\s(.*)$/gm, '<li>$1</li>');
    text = text.replace(/(<li>.*?<\/li>)(?!<\/li>)/gs, '$1</ul>');
    // text = `<ul>${text}</ul>`;
  
    // Handle quotes (e.g., > Quote)
    text = text.replace(/^>\s(.*)$/gm, '<blockquote>$1</blockquote>');
  
    // Handle links (e.g., [link](https://example.com))
    text = text.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>');
  
    // Replace line breaks with <br>
    text = text.replace(/\n/g, '<br>');
  
    return text;
  };
  
  

  const formattedContent = formatText(content);

  return <Div dangerouslySetInnerHTML={{ __html: formattedContent }} />;
};

export default FormattedContent;

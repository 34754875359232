import { lazy, useEffect, useState } from 'react';
import { styled, Grid } from '@mui/material';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getProducts } from '../../api/products';
import { truncateSync } from 'fs';
import PublicRoute from '../../router/PublicRoute';

const Container = lazy(() => import('../../common/Container'));
const ScrollToTop = lazy(() => import('../../common/ScrollToTop'));
const starsArray = Array(5).fill(null);

interface Content {
  Title: string;
  Content: string;
  // Add other properties as needed
}

interface Product {
  name: string;
  cover: string;
  price: number;
  category: string;
  // Add other properties as needed
}

const Tienda = () => {
  const [products, setProducts] = useState<Product[]>([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleGetProducts();
  }, []);

  const handleGetProducts = async () => {
    try {
      const productList = await getProducts();
      console.log('productList', productList);
      if (productList.data.length > 0) {
        console.log('All gud');
        setProducts(productList.data);
      } else {
        console.log('Algo salió mal');
      }
      setLoading(false);
    } catch (e) {
      console.log('e: ', e);
    }
  };

  console.log('products', products);

  return (
<PublicRoute>
    <Container>
      {loading ? (
        null
      ) : (
        <CardGrid>
          {products.map((item) => {
            return (
              <Card>
                <Image
                  src={`${process.env.REACT_APP_API_URL}/marketplace/api/files/${item.cover}`}
                />
                <TextInCard>
                  <Title>{item.name}</Title>
                  <Description>
                    Desde{' '}
                    {item.price.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                    })}
                  </Description>
                  <Description>Categoría: {item.category}</Description>
                </TextInCard>
              </Card>
            );
          })}
        </CardGrid>
        )}
    </Container>
    </PublicRoute>
  );
};
  {/* return (
    <Container>
      <ScrollToTop />
      {loading ? (
        null
      ) : (
        <CardGrid>
          {products.map((item) => {
            return (
              <Card>
                <Image
                  src={`${process.env.REACT_APP_API_URL}/marketplace/api/files/${item.cover}`}
                />
                <TextInCard>
                  <Title>{item.name}</Title>
                  <Description>
                    Desde{' '}
                    {item.price.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                    })}
                  </Description>
                  <Description>Categoría: {item.category}</Description>
                </TextInCard>
              </Card>
            );
          })}
        </CardGrid>
    </Container>
  );
}; */}
export default Tienda;

const CardGrid = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gap: '2rem',
  width: '100%',
  gridTemplateColumns: `repeat(4, 3fr)`,
  padding: '2rem',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: `repeat(2, 1fr)`, // Change to two columns on small screens
    padding: '.5rem',
  },
}));

const Card = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100%',
  gap: '1rem',
  borderRadius: '7px',
  padding: '2rem',
  boxShadow: '0px 2.82353px 14.11765px 0px rgba(0, 0, 0, 0.25);',
  fontFamily: 'Avenir',
  color: '#434343',
  backgroundColor: '#DBDBDB',
  [theme.breakpoints.down('sm')]: {
    padding: '.5rem',
  },
}));

const TextInCard = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  fontFamily: 'Avenir',
  color: '#434343',
}));

const Title = styled('div')(({ theme }) => ({
  color: '#434343',
  // fontFamily: 'Poppins',
  fontSize: '19.765px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
}));

const Description = styled('div')(({ theme }) => ({
  color: '#434343',
  // fontFamily: 'Poppins',
  fontSize: '16.941px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
}));

const Image = styled('img')(({ theme }) => ({
  width: '150px',
  borderRadius: '7.059px',
  // border: 'none',
  height: 'auto',
  // background: `url('https://s3-alpha-sig.figma.com/img/3fc5/f320/396fad8c387e64820179b3ba6f85f049?Expires=1702252800&Signature=Wjt9iW4jtl1ROXGna9QbyEA7epsO-vQy24UOqbzEJynsrMeP9ySTy-r2QDDcfEftl~TZ7ZHoG~H9GAEqGb4VMdIPtTgOVjJD29eHPXhwSDbG5pdbVfBoxuKgZi-SLYXqvyg~srl5bMDiL18tL~VKFrnU81Pqj8wYP38VsKIh3xW4zJtYjOz4qWktCHoRHpViSfWZODJszpWTKs8QQf1ZilKXugCaB8YeqNu7oBzJkytVJtvzzJ0lK3gXyY9FfH37xyf3INqXHus0pWps3TphcAbOAJ8f2LFEa~b86w93yl15vPa-eOJ-eDuRBlVtSbNn36IU2CSIZNtT4GUTy-OWJg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100px',
  },
}));

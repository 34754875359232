import useAuth from '../../auth/useAuth';
import { Navigate, useLocation } from 'react-router-dom';
import SideMenu from '../SideMenu';
import { LogoContainer } from '../../components/Header/styles';

const ProtectedRoute = (props) => {
  const { session } = useAuth();
  const location = useLocation();

  if (!session) {
    return <Navigate to='/login' replace state={{ from: location }} />;
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '150px',
            padding: 20,
            position: 'sticky', // Fixed position
            top: 0, // Fixed at the top
            left: 0, // Fixed at the left
            height: '100vh', // Take full height
          }}
        >
          <LogoContainer to='/' aria-label='homepage'>
            <img
              src={`${process.env.REACT_APP_CMS_URL}/uploads/luziapp_isotype_56943504a9.svg`}
              width='150px'
              alt='logo-luziapp'
              height='64px'
            />
          </LogoContainer>
          <SideMenu />
        </div>
        <div
          style={{
            flex: 1, // Grow to take remaining space
            overflowY: 'auto', // Add scrollbar if content overflows
          }}
        >
          {props.children}
        </div>
      </div>
    </>
  );
};

export default ProtectedRoute;

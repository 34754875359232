import React, { createContext, useContext, useState, useEffect } from 'react';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const ContextProvider = ({ children }) => {
  // const defaultStoreId = localStorage.getItem('selectedStoreId') || null;
  const [selectedStore, setSelectedStore] = useState(null);

  const setSelectedStoreValue = (value) => {
    setSelectedStore(value);
    // localStorage.setItem('selectedStoreId', value);
  };

  // useEffect(() => {
  //   localStorage.setItem('selectedStoreId', storeId || '');
  // }, [storeId]);

  return (
    <AppContext.Provider value={{ selectedStore, setSelectedStore: setSelectedStoreValue }}>
      {children}
    </AppContext.Provider>
  );
};
